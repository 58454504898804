import React, { useMemo } from "react";
import Image from "next/image";
import ButtonPrimary from "./misc/ButtonPrimary";
import { motion } from "framer-motion";
import getScrollAnimation from "../utils/getScrollAnimation";
import ScrollAnimationWrapper from "./Layout/ScrollAnimationWrapper";
import { Dialog } from "@headlessui/react";
import GoToAppModal from "./GoToAppModal";

const Hero = ({
  listUser = [
    {
      name: "Gebruikers",
      number: "200",
      icon: "/assets/Icon/user.svg",
    },
    {
      name: "Fasen berekend",
      number: "5000",
      icon: "/assets/Icon/brain-circuit.svg",
    },
    {
      name: "Dagelijks gebruik pp",
      number: "2,5",
      icon: "/assets/Icon/reservation-smartphone.svg",
    },
  ],
}) => {
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <div className="max-w-screen-xl mt-24 px-8 xl:px-16 mx-auto" id="about">
        <ScrollAnimationWrapper>
          <motion.div
            className="grid grid-flow-row sm:grid-flow-col md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16"
            variants={scrollAnimation}
          >
            <div className=" flex flex-col sm:justify-center items-start row-start-2 sm:row-start-1">
              <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-black-600 leading-normal">
                Het digitaal signaleringsplan
              </h1>
              <p className="text-black-500 mt-4 mb-0">
                Overzicht van je week, envoudig scoren en altijd bij de hand.
              </p>
              <p className="text-black-500 mt-1 mb-6">
                Ontworpen door zorgverleners voor zorgverleners.
              </p>
              <ButtonPrimary onClick={() => setShowModal(true)}>
                Ga naar de app
              </ButtonPrimary>
            </div>
            <div className="flex w-full">
              <motion.div className="h-full w-full" variants={scrollAnimation}>
                <Image
                  src="/assets/Illustration1.png"
                  alt="Sipi screenshots signaleren"
                  quality={100}
                  width={2482.81}
                  height={3185.55}
                  layout="responsive"
                />
              </motion.div>
            </div>
          </motion.div>
        </ScrollAnimationWrapper>
        <div className="relative w-full flex">
          <ScrollAnimationWrapper className="rounded-lg w-full grid grid-flow-row sm:grid-flow-row grid-cols-1 sm:grid-cols-3 py-9 divide-y-2 sm:divide-y-0 sm:divide-x-2 divide-gray-100 bg-white-500 z-10">
            {listUser.map((listUsers, index) => (
              <motion.div
                className="flex items-center justify-start sm:justify-center py-4 sm:py-6 w-8/12 px-4 sm:w-auto mx-auto sm:mx-0"
                key={index}
                custom={{ duration: 2 + index }}
                variants={scrollAnimation}
              >
                <div className="flex mx-auto w-80 sm:w-auto">
                  <div className="flex items-center justify-center bg-orange-100 w-12 h-12 mr-6 rounded-full">
                    <img src={listUsers.icon} className="h-6 w-6" />
                  </div>
                  <div className="flex w-40 sm:w-auto flex-col">
                    <p className="text-xl text-black-600 font-bold">
                      {listUsers.number}+
                    </p>
                    <p className="text-lg text-black-500">{listUsers.name}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </ScrollAnimationWrapper>
          <div
            className="absolute bg-black-600 opacity-5 w-11/12 roudned-lg h-64 sm:h-48 top-0 mt-8 mx-auto left-0 right-0"
            style={{ filter: "blur(114px)" }}
          ></div>
        </div>
      </div>
      <GoToAppModal isOpen={showModal} setIsOpen={setShowModal} />
    </>
  );
};

export default Hero;
